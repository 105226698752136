<template>
  <div
    class="date-picker-container"
    :class="[
      containerClass,
      variant
    ]"
  >
    <slot name="label">
      <label
        v-if="label"
        :class="labelClass"
      >
        {{ label }}
        <span
          v-if="isRequired"
          class="text-primary-red"
        >*</span>
      </label>
    </slot>

    <date-picker
      v-bind="attrs"
      :model-value="date"
      class="w-full"
      @update:modelValue="updateDate($event)"
    >
      <template #default="{ inputValue, togglePopover }">
        <div class="flex">
          <div class="w-full h-full relative">
            <input
              :value="showInputValue(inputValue)"
              type="text"
              :placeholder="placeholder"
              class="focus"
              :class="[
                inputClass,
                error ? 'error' : '',
                inputSize,
                textSize
              ]"
              :disabled="disabled"
              readonly
              @focus="handlePopOverToggle(togglePopover)"
            >
            <slot name="errorIcon">
              <base-svg
                v-if="error"
                src="icons/errorCircle.svg"
                class="text-red-600 h-4 w-4 absolute right-1.5 bottom-1/2 transform translate-y-1/2"
                :svg-attributes="{
                  class: 'w-full h-full'
                }"
                tag="div"
              />
            </slot>
          </div>
          <div>
            <slot name="calenderButton">
              <base-svg
                v-bind="calendarBtnProps"
                src="icons/calendar.svg"
                class="date-picker-calendar-btn"
                :svg-attributes="{
                  class: 'w-3 h-4'
                }"
                tag="button"
                @click.stop.prevent="handlePopOverToggle(togglePopover)"
              />
            </slot>
          </div>
        </div>
        <small
          v-if="error"
          class="block mb-2 text-red-600"
        >{{ error }}</small>
      </template>
    </date-picker>
    <div class="flex">
      <a
        v-if="isTodayVisible"
        v-bind="attrs"
        class="today-selection"
        :class="disabledToday ? 'disable-today' : ''"
        @click="onClickToday()"
      >
        TODAY
      </a>
      <a
        v-bind="attrs"
        class="today-selection"
        :class="disabledToday ? 'disable-today' : ''"
        @click="onResetClick()"
      >
        RESET
      </a>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue';
import 'v-calendar/dist/style.css';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import { useStore } from 'vuex';

export default {
    name: 'BaseDatePicker',

    components: {
        DatePicker,
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    inheritAttrs: false,

    props: {
        modelValue: {
            type: [Date, String],
            required: true,
            description: 'bound value(value should be date, use string only for empty values)'
        },
        placeholder: {
            type: String,
            default: '',
            description: 'placeholder text'
        },
        error: {
            type: String,
            default: '',
            description: 'error message for switching layout of input to error layout'
        },
        containerClass: {
            type: [Array, Object, String],
            default: '',
            description: 'Classes applied to root element'
        },
        dropdownBtnClass: {
            type: [Array, Object, String],
            default: '',
            description: 'Classes applied to dropdown button'
        },
        inputClass: {
            type: [Array, Object, String],
            default: 'date-picker-input',
            description: 'Classes applied to input element'
        },
        disabled: {
            type: Boolean,
            default: false,
            description: 'disables entire component'
        },
        calendarBtnProps: {
            type: Object,
            default: () => {},
            description: 'props for calender button(check base-svg component for valid prop options)'
        },
        label: {
            type: String,
            default: '',
            description: 'label for date picker'
        },
        labelClass: {
            type: String,
            default: 'block text-sm text-black mb-1',
            description: 'css classes applied on label'
        },
        isRequired: {
            type: Boolean,
            default: false,
            description: 'adds asterisk to label to denote required field'
        },
        variant: {
            type: String,
            default: 'date-picker-default',
            description: 'date picker variant (check variants in style section)'
        },
        inputSize: {
            type: String,
            default: 'px-2 py-1',
            description: 'input element size (use padding classes to control size)'
        },
        textSize: {
            type: String,
            default: 'text-sm',
            description: 'css classes for text size'
        },
        isTodayVisible: {
            type: Boolean,
            default: true,
            description: 'show today selection'
        },
        datePickerNeverDisable: {
            type: Boolean,
            default: false,
            description: 'show today selection always from BA advance search'
        }
    },

    emits: [
        'update:modelValue'
    ],

    setup (props, { emit, attrs }) {
        // 2-way binding logic
        const date = ref(props.modelValue);
        const { submissionForm, submissionFormMode  } = useSubmissionForm();
        // const { submissionForm, submissionFormMode } = useSubmissionForm();
        const store = useStore();

        watch(
            () => props.modelValue,
            () => {
                date.value = props.modelValue;
            }
        );
        const updateDate = (newValue) => {
            date.value = newValue;
            emit('update:modelValue', date.value);
        };

        // date-picker toggle logic
        const handlePopOverToggle = (togglePopOver) => {
            if (props.disabled) return; // if prop disabled handle should not work.
            if (!props.disabled || !attrs.disabled) {
                togglePopOver();
            }
        };

        const userId = computed(() => store.getters['auth/getUserId']);
        const showToday = ref(false);

        const disabledToday = ref('');
        onMounted(() => {
            if (props.datePickerNeverDisable) return;
            if (submissionFormMode.value === 'NEW') return;
            if (submissionForm.assignUser !== userId.value) disabledToday.value = true;
        });

        watch(
            () => submissionForm.assignUser,
            () => {
                if (submissionFormMode.value === 'NEW') {
                    disabledToday.value = false;
                } else {
                    submissionForm.assignUser !== userId.value ? disabledToday.value = true : disabledToday.value = false;
                }
            }
        );

        const onResetClick = () => {
            if (submissionFormMode.value === 'NEW') date.value = null;
            if (props.disabled) return; // if prop disabled on click today should not work.
            if (props.datePickerNeverDisable) {
                date.value = null;
            }
            if (submissionForm.assignUser !== userId.value) return;
            date.value = null;
        };

        const onClickToday = () => {
            if (submissionFormMode.value === 'NEW') date.value = Date.now();
            if (props.disabled) return; // if prop disabled on click today should not work.
            if (props.datePickerNeverDisable) {
                date.value = Date.now();
            }
            if (submissionForm.assignUser !== userId.value) return;
            date.value = Date.now();
        };

        const showInputValue = (inputValue) => {
            if (!inputValue) return '';
            if (typeof inputValue === 'object' && (!inputValue.start || !inputValue.end)) return '';

            if (inputValue.start && inputValue.end) return `${inputValue.start} - ${inputValue.end}`;
            else return inputValue;
        };

        return {
            date,
            updateDate,
            attrs,
            handlePopOverToggle,
            showInputValue,
            onClickToday,
            showToday,
            disabledToday,
            onResetClick
        };
    }
};
</script>

<style>

.today-selection {
    margin-left: 5px;
    background: red;
    color: white;
    padding: 4px 8px;
    border-radius: 2px;
    font-size: 10px;
    cursor: pointer;
    height: fit-content;
}

.disable-today {
  @apply cursor-not-allowed opacity-70
}
/* @NOTE: do not scope this style block with scoped keyword otherwise css would not be applied to vue date-picker plugin, use css classes to scope css. For detailed info, check how scoped works in offical docs */
.vc-pane-container select {
  @apply bg-none;
}

@layer components {
  .date-picker-container {
    /* date picker variants */
    &.date-picker-default {
      .date-picker-input {
        @apply rounded-sm border border-custom-gray-8 text-custom-gray-7 flex items-center w-full;
      }
      .date-picker-calendar-btn {
        @apply float-right h-full w-7 text-custom-gray-2 bg-primary-red flex justify-center items-center border border-primary-red rounded-r-sm cursor-pointer;
      }
    }

    /* date picker input states */
    .date-picker-input {
      &:disabled {
        @apply bg-custom-gray-6 cursor-not-allowed opacity-70;
      }
      &:focus {
          @apply focus:outline-none focus:ring-0 focus:border-blue-600;
      }
    }
    .error {
        @apply border border-red-600;
    }

    /* date picker calendar button state */
    .date-picker-calendar-btn {
      &:disabled {
        @apply cursor-not-allowed opacity-70;
      }
    }

    /* label variants */
    .label-w-46 {
      @apply block text-sm text-black mr-3.5 w-46 flex-shrink-0;
    }
    .label-w-36 {
      @apply block text-sm text-black mr-3.5 w-36 flex-shrink-0;
    }
  }
}
</style>
